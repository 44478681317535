<template>
  <div class="puntos mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Puntos
          </v-card-title>
          <v-card-subtitle>
            Desde este apartado podrás ver los puntos de cada cliente y sus
            movimientos con ellos.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  hide-default-footer
                  :loading="loading_t"
                  :items="clientes"
                  :page.sync="page"
                  :items-per-page="perPage"
                  dense
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row dense>
                        <v-col cols="4">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <div class="mt-1">
                            <strong style="font-size: 1.5em;">
                              Total Clientes: {{ totalClientes }}
                            </strong>
                          </div>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                </v-data-table>

                <v-pagination
                  v-model="page"
                  :length="lengthPagination"
                  :total-visible="7"
                  @input="changePage"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    search: "",
    perPage: 15,
    lengthPagination: 0,
    page: 1,
    loading_t: false,
    clientes: [],
    totalClientes: 0,
    headers: [
      {
        text: "ID",
        value: "cedula",
        align: "start",
      },
      {
        text: "Cliente",
        value: "nombre",
      },
      {
        text: "Puntos actuales",
        value: "puntos",
      },
      {
        text: "Puntos canjeados",
        value: "canjeados",
      },
    ],
  }),
  methods: {
    loadClientes() {
      this.loading_t = true;
      const body = {
        route: "/puntos_clientes",
        params: {
          page: this.page,
          search: this.search,
          perPage: this.perPage,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
            let links = response.data.pagination.links;
            this.totalClientes = response.data.pagination.total;

            links.splice(0, 1);
            links.splice(links.length - 1, 1);
            this.lengthPagination = links.length;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    changePage(data) {
      this.clientes = [];
      this.page = data;
      this.loadClientes();
    },
  },
  created() {
    this.loadClientes();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    search() {
      this.loadClientes();
      return this.search;
    },
  },
};
</script>
